import { FC, useMemo } from 'react';
import Row from './Row';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';

const Stats: FC = (props) => {
  const delay = useMemo(() => Math.floor(Math.random() * 4000 - 4000), []);

  return (
    <div
      {...dataAttributeProps(props)}
      className="flex h-full w-full animate-pulse flex-col items-center justify-between p-8"
      style={{ animationDelay: `${delay}ms` }}
    >
      <div className="relative h-40 w-40">
        <div className="border-gray-6 absolute left-1/2 top-1/2 h-36 w-36 -translate-x-1/2 -translate-y-1/2 rounded-full border-8 bg-transparent" />
        <div className="border-gray-6 absolute left-1/2 top-1/2 h-28 w-28 -translate-x-1/2 -translate-y-1/2 rounded-full border-8 bg-transparent" />
      </div>
      <div className="flex w-full justify-between space-x-32 px-8">
        <Row />
        <Row />
      </div>
    </div>
  );
};

export default Stats;
