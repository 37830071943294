import { FC, useMemo } from 'react';
import { Props } from './Props';
import Row from './Row';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';

const RowDetail: FC<Props> = (props) => {
  const { rows = 1 } = props;

  const delay = useMemo(() => [...Array(rows)].map(() => Math.floor(Math.random() * 4000 - 4000)), [rows]);

  return (
    <div {...dataAttributeProps(props)} className="flex h-full w-full flex-col justify-center space-y-4">
      {[...Array(rows)].map((_, i) => (
        <div
          key={i}
          className="flex animate-pulse flex-col items-center justify-center gap-1 border-b pb-4"
          style={{ animationDelay: `${delay[i]}ms` }}
        >
          <div className="flex w-full flex-col">
            <Row />
          </div>
          <div className="flex w-full flex-row gap-4">
            <Row className="flex-grow" /> <Row className="w-[40px]" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default RowDetail;
