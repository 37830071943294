import { FC, useMemo } from 'react';
import { Props } from './Props';
import Row from './Row';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';

const InputField: FC<Props & { cols?: boolean }> = (props) => {
  const { rows = 1, cols } = props;

  const delay = useMemo(() => [...Array(rows)].map(() => Math.floor(Math.random() * 4000 - 4000)), [rows]);

  return (
    <div {...dataAttributeProps(props)} className="flex h-full w-full flex-col justify-center space-y-2">
      {[...Array(rows)].map((_, i) => {
        const random = Math.floor(Math.random() * 3);
        let secondarySize = '';
        switch (random) {
          case 0:
          default:
            secondarySize = 'w-[60%]';
            break;
          case 1:
            secondarySize = 'w-[30%]';
            break;
          case 2:
            secondarySize = 'w-[20%]';
            break;
        }

        return (
          <div key={i} className="bg-white p-4">
            <div className="flex animate-pulse flex-row items-center justify-center space-x-5" style={{ animationDelay: `${delay[i]}ms` }}>
              {cols && (
                <div className="flex w-full flex-row gap-4">
                  <div className={`flex w-[40%] flex-col gap-1`}>
                    <Row size="small" />
                    <Row size="small" className={secondarySize} />
                  </div>
                  <Row size="medium" />
                </div>
              )}
              {!cols && (
                <>
                  <div className="flex w-full flex-col gap-4">
                    <Row size="small" className={secondarySize} />
                    <Row size="medium" />
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InputField;
