import { ComponentProps, Suspense, useMemo } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import * as variants from './variants';

type SkeletonProps = {
  ready: boolean;
  className?: string;
  rows?: number;
  size?: 'small' | 'medium';
};

type BlockVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.block>, 'rows' | 'children'> & {
    type: 'block';
  };

type RowVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.row>, 'rows' | 'children'> & {
    type: 'row';
  };

type BlockRowVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.blockRow>, 'rows' | 'children'> & {
    type: 'blockRow';
  };

type DualBlockRowVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.dualBlockRow>, 'rows' | 'children'> & {
    type: 'dualBlockRow';
  };

type ListBlockRowVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.listBlockRow>, 'rows' | 'children'> & {
    type: 'listBlockRow';
  };

type StatsVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.stats>, 'children'> & {
    type: 'stats';
  };

type RowDetailVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.rowDetail>, 'rows' | 'children'> & {
    type: 'rowDetail';
  };

type InputFieldVariantProps = SkeletonProps &
  Omit<ComponentProps<typeof variants.inputField>, 'rows' | 'children'> & {
    type: 'inputField';
  };

export type Props =
  | BlockVariantProps
  | RowVariantProps
  | DualBlockRowVariantProps
  | BlockRowVariantProps
  | StatsVariantProps
  | ListBlockRowVariantProps
  | RowDetailVariantProps
  | InputFieldVariantProps;

const SkeletonLoader: FCWithChildren<Props> = (props) => {
  const { ready, type = 'row', children, className, size = 'small', rows = 1, ...rest } = props;

  const skeleton = useMemo(() => {
    const Skeleton = variants[type];
    return <Skeleton {...rest} rows={rows} className={className} size={size} data-cy="skeleton-loader" />;
  }, [className, rest, rows, size, type]);

  return <Suspense fallback={skeleton}>{ready ? children : skeleton}</Suspense>;
};

export default SkeletonLoader;
