import { FC, useMemo } from 'react';
import { Props } from './Props';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';

const Block: FC<Props & { rounded?: boolean }> = (props) => {
  const { rounded, className, size = 'small' } = props;

  const squareSize = useMemo(() => (size === 'small' ? 'w-[22px] h-[22px]' : 'w-[28px] h-[28px]'), [size]);
  return <div {...dataAttributeProps(props)} className={`${squareSize} bg-gray-6 ${rounded ? 'rounded-full' : 'rounded-md'} ${className}`}></div>;
};

export default Block;
