import { FC } from 'react';
import { Props } from './Props';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';

const Row: FC<Props> = (props) => {
  const { rows = 1, className, size = 'small' } = props;

  return (
    <>
      {[...Array(rows)].map((_, i) => (
        <div
          {...dataAttributeProps(props)}
          key={i}
          className={`bg-gray-6 w-full ${size === 'small' ? 'h-[10px]' : 'h-[20px]'} rounded-[10px] ${className}`}
        ></div>
      ))}
    </>
  );
};

export default Row;
